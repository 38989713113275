import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import { Navbar } from 'library';


class GeneralNavbar extends Component {

    render() {

        return (
            <Navbar name={'Lead Magic'} style={{position:'absolute', top:'0px', left: '0px', width: '100%'}} >
                {[<a className="nav-link" href={'/logout/'}>{'Logout'}</a>]}
            </Navbar>
        );
    }
}

export default GeneralNavbar;

import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, LogInForm, Header, Div, Container, Span, Link, NavLink, Image, Paragraph} from 'library';

import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


class Home extends Component {

    render() {

        return (<div>
				<Navbar />

				<Container style={{'padding-top': '20px'}} min_height={true} >
                    {'Welcome To Loan Garden'}
				</Container>

				<Footer />
        </div>);
    }
}

export default Home;

import 'babel-polyfill';
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11';

import React, { Component } from 'react';
import './App.css';
import {ajaxWrapper, setGlobalState, clear_tokens} from "functions";
import {Wrapper} from 'library';
import * as Sentry from '@sentry/browser';

import LogIn from './pages/login.js';

import Home from './pages/home.js';
import Dashboard from './pages/dashboard.js';
import OutOfOffice from './pages/out_of_office.js';



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_loaded: false,
            context_loaded: false,

            csrfmiddlewaretoken: undefined,
            user:{}
        };

        this.ajax_callback = this.ajax_callback.bind(this);
        this.load_user = this.load_user.bind(this);
    }

    componentDidMount() {
        ajaxWrapper("GET", "/api/csrfmiddlewaretoken/", {}, this.ajax_callback);

        var path = this.getURL()[0].toLowerCase();

        var token = localStorage.getItem('token');

        var loginNoRedirects = ['', 'login'];

        if (token) {
            ajaxWrapper("GET", "/users/user/", {}, this.load_user);

            this.setState({token: token});
            if (path.indexOf('login') > -1) {
                window.location.href = '/dashboard/';
            }
        } else if (loginNoRedirects.indexOf(path) == -1 && window.location.pathname != "/") {
            window.location.href = '/login/';
        }
        else {
          this.setState({'user_loaded':true})
        }
    }

    ajax_callback(value) {
        window.secretReactVars["csrfmiddlewaretoken"] = value.csrfmiddlewaretoken;

        if ('glitchtip_dsn' in value){
            Sentry.init({
                dsn: value['glitchtip_dsn'],
                debug: true,
            });
        }

        this.setState({
            context_loaded: true
        });
    }

    load_user(result) {
        window.cmState.setGlobalState('user',result);

        this.setState({
            user: result,
            user_loaded: true,
        });
    }

    logOut() {
        console.log("Log Out");
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_time');
        window.location.href = '/users/logout/';
    }

    getURL() {
        var url = window.location.pathname;
        if (url[0] == '/'){ url = url.substring(1);}
        if (url[url.length - 1] == '/'){ url = url.substring(0,url.length-1);}
        var url_split = url.split('/');

        var params = {};
        for (var index in url_split) {
            params[index] = url_split[index]
        }

        var href = window.location.href;
        if (href.indexOf('?') > -1) {
            console.log("Here")
            var post_params = href.split('?')[1];
            var split_params = post_params.split('&');
            console.log("Post Params", post_params, split_params);

            for (var index in split_params) {
                var temp = split_params[index]
                var temp_split = temp.split('=')
                params[temp_split[0]] = temp_split[1]
            }
        }

        return params;
    }

    render() {
        var params = this.getURL();
        var param_dict = {};
        for (var index in params) {
            param_dict[index.toString()] = params[index]
        }
        window.cmState.setGlobalState('params',param_dict)
        window.cmState.setGlobalState('location', window.location)

        var adminPages = [];

        var loggedInPages = [];
        var route = params[0].toLowerCase();

        var loading = <h1>Loading . . . </h1>;
        var content = null;

        var loaded = this.state.user_loaded && this.state.context_loaded;

        if (loaded) {
            if (adminPages.indexOf(route) > -1 && this.state.loaded && !(this.state.user.is_staff)){
                //window.location = '/';
                console.log("Not an admin", this.state.loaded, this.state.user)
            } else if (loggedInPages.indexOf(route) > -1 && this.state.loaded && typeof(this.state.user.id) != 'undefined'){
                //window.location = '/login/';
                console.log("Need to be logged in");
            }
            else if (route == 'logout') {
                this.logOut();
            }

            else if (route == "login") {
    			content = <LogIn />;
    		}

            else if (route == 'dashboard') {
                content = <Dashboard />;
            }
            else if (route == 'out_of_office') {
                content = <OutOfOffice />;
            }

            else if (route == '') {
                content = <Home />;
            }
        }

        return (
            <Wrapper style={{paddingTop: '60px'}} loaded={loaded} >
                {content}
            </Wrapper>
        );
    }
}

export default App;

import React, { Component } from 'react';
import {ajaxWrapper, format_date_string} from 'functions';

import { Wrapper, FormWithChildren, Header, Div, Container, Select, TextInput, PageBreak, NumberInput } from 'library';

import Navbar from 'projectLibrary/navbar.js';
import Footer from 'projectLibrary/footer.js';


var EXPERIAN_FIELDS = [
    'Capture Time.',
    'Last Name',
    'First Name',
    'House Number',
    'Address',
    'Address Line 2',
    'City',
    'State / Province',
    'Zip',
    'Credit', // FICO
    '1st Mortgage Balance', // Loan Amount
    'Date Mortgage Closed',
    '1st Mortgage Interest',
    '2nd Mortgage Balance',
    'Unsecured Debt Balances', // Unsecured Debt
    'Phone (Home)',
    'Phone (Work)',
    'Phone (Mobile)',
    'LeadSource ID',
];


class OutOfOffice extends Component {
    constructor(props) {
        super(props);
    }

    update(){
        window.location.reload();
    }

    render (){
        var out = this.props.data;

        var start = format_date_string(out.start_date, 'mm-dd-yyyy', 'UTC');
        var end = format_date_string(out.end_date, 'mm-dd-yyyy', 'UTC');

        var delete_form = <div >
            <FormWithChildren deleteUrl={'/api/bonzo/OutOfOffice/'+ out['id'] +'/delete/'} delete_text={'Delete'} redirect={this.update} />
        </div>;

        return (
            <div className='simple-grey-tag' >
                <div style={{padding:'4px'}}>{'Stop leads on ' + start}</div>
                <div style={{padding:'4px'}}>{'Start leads on ' + end}</div>
                {delete_form}
            </div>
        )
    }
}

class WorkingWeekend extends Component {
    constructor(props) {
        super(props);
    }

    update(){
        window.location.reload();
    }

    render (){
        var working = this.props.data;

        var date = format_date_string(working.date, 'mm-dd-yyyy', 'UTC');

        var delete_form = <div >
            <FormWithChildren deleteUrl={'/api/bonzo/WorkingWeekend/'+ working['id'] +'/delete/'} delete_text={'Delete'} redirect={this.update} />
        </div>;

        return (
            <div className='simple-grey-tag' >
                <div style={{padding:'4px'}}>{date}</div>
                {delete_form}
            </div>
        )
    }
}

class Rule extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    update(){
        window.location.reload();
    }

    render (){
        var rule = this.props.data;
        var incoming_options = [];
        for (var item of EXPERIAN_FIELDS){
            incoming_options.push({
                'text': item,
                'value': item,
            });
        }

        var outgoing_options = [];

        var submit_url = '/api/home/routingrule/';
        var submit_text = 'Create';
        if (this.props.data){
            submit_url = '/api/home/routingrule/'+ rule['id'] +'/';
            submit_text = 'Update';
        }

        return (
            <div className='card' >
                <FormWithChildren row={true} submitUrl={submit_url} submit_text={submit_text} submitClassName='col-2 special-submit'
                    redirect={this.update} defaults={rule || {integration: this.props.integration['id']}} >
                    <TextInput name='integration' value={this.props.integration['id']} style={{display: 'none'}} />

                    <NumberInput className='col-1' name='priority' label='Priority' />
                    <Select className='col-3' name='incoming_field_name' label='Incoming Field' options={incoming_options} />
                    <TextInput className='col-3' name='incoming_field_value' label='Incoming Value' />

                    <Select className='col-3' name='outgoing_field_name' label='Outgoing Value' options={this.props.outgoing_options} />
                </FormWithChildren>
            </div>
        )
    }
}

class Campaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    update(){
        window.location.reload();
    }

    render (){
        var campaign = this.props.data;

        var submit_url = '/api/bonzo/campaign/';
        var submit_text = 'Create';
        if (this.props.data){
            submit_url = '/api/bonzo/campaign/'+ campaign['id'] +'/';
            submit_text = 'Update';
        }

        return (
            <div className='card' >
                <FormWithChildren row={true} submitUrl={submit_url} submit_text={submit_text} submitClassName='col-3 special-submit'
                    redirect={this.update} defaults={campaign || {integration: this.props.integration['id']}} >
                    <TextInput name='integration' value={this.props.integration['id']} style={{display: 'none'}} />

                    <TextInput className='col-3' name='name' label='Name' />
                    <TextInput className='col-3' name='bonzo_id' label='Bonzo ID' />
                    <Select boolean={true} className='col-3' name='round_robin' label='Round Robin' />
                </FormWithChildren>
            </div>
        )
    }
}

class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
        };

        this.update = this.update.bind(this);
    }

    componentDidMount(){
        this.setState({
            user: this.props.data,
        });
    }

    update(value){
        var new_user = Object.assign(this.state.user, value[0]['user']);

        this.setState({
            user: new_user,
        });
    }

    render (){
        var user = this.state.user;

        if (user && !user.active){
            return null;
        }
        
        var submit_url = '/api/bonzo/user/';
        var submit_text = 'Create';
        var delete_url = null;
        if (user){
            submit_url = '/api/bonzo/user/'+ user['id'] +'/';
            submit_text = 'Update';
            delete_url = '/api/bonzo/user/'+ user['id'] +'/delete/';;
        }

        var out_of_office = [];
        var out_of_office_jsx = null;
        var working_weekends = [];
        var working_weekends_jsx = null;
        if (user){
            for (var item of user.out_of_office){
                var out = item['outofoffice'];

                var end_date = new Date(Date.parse(out.end_date));
                var now = new Date();

                if (now > end_date){
                    continue;
                }

                out_of_office.push(<OutOfOffice data={out} update={this.update} />);
            }

            if (out_of_office.length > 0){
                out_of_office_jsx = <div>
                    <h6>Out Of Office:</h6>
                    {out_of_office}
                </div>
            }

            for (var item of user.working_weekends){
                var working = item['workingweekend'];

                var date = new Date(Date.parse(working.date));
                var now = new Date();

                if (now > date){
                    continue;
                }

                working_weekends.push(<WorkingWeekend data={working} update={this.update} />);
            }

            if (working_weekends.length > 0){
                working_weekends_jsx = <div>
                    <h6>Working Weekends:</h6>
                    {working_weekends}
                </div>
            }

        }

        return (
            <div className='card' style={{marginBottom:'10px'}}>
                <FormWithChildren row={true} submitUrl={submit_url} deleteUrl={delete_url} submit_text={submit_text} submitClassName='col-2 special-submit'
                    redirect={this.update} defaults={user || {integration: this.props.integration['id']}} >

                    <TextInput className='col-3' name='name' label='Name' />
                    <TextInput className='col-2' name='bonzo_id' label='Bonzo ID' />
                    <TextInput className='col-2' name='multiplier' label='Multiplier' />
                    <TextInput className='col-3' name='weekend_limit' label='Weekend Limit' />
                </FormWithChildren>

                <div style={{padding:'10px 5px'}}>
                    <PageBreak />
                </div>

                {out_of_office_jsx}
                {working_weekends_jsx}
            </div>
        )
    }
}


class Integration extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render (){
        var integration = this.props.data;

        var bonzo_details = null;
        var rules = [];
        var outgoing_options = [];

        if (integration['platform'] == 'Bonzo'){
            var campaigns = [];
            var users = [];

            for (var item of integration['campaigns']){
                var campaign = item['campaign'];
                campaigns.push(<Campaign integration={integration} data={campaign} />);

                outgoing_options.push({
                    'text': campaign['name'],
                    'value': campaign['id'],
                });
            }

            campaigns.push(<Campaign integration={integration} data={null} />);

            var ordered_users = integration['users'].sort((a, b) => (a['user']['name'] > b['user']['name']) ? 1 : -1);
            for (var item of ordered_users){
                var user = item['user'];
                users.push(<User integration={integration} data={user} />);
            }

            users.push(<User integration={integration} data={null} />);

            bonzo_details = [
                <div className='card-container' style={{marginBottom:'15px'}} ><h4 className='card'>{'Campaigns'}</h4>{campaigns}</div>,
                <div className='card-container' style={{marginBottom:'15px'}} ><h4 className='card'>{'Users'}</h4>{users}</div>,
            ];
        }

        var ordered_rules = [];
        for (var item of integration['rules']){
            var rule = item['routingrule'];
            ordered_rules.push(rule);
        }
        ordered_rules.sort((a, b) => (a.priority > b.priority) ? 1 : -1);

        for (var rule of ordered_rules){
            rules.push(<Rule integration={integration} data={rule} outgoing_options={outgoing_options} />);
        }

        rules.push(<Rule integration={integration} data={null} outgoing_options={outgoing_options} />);

        return (
            <div style={{margin:'40px 0px'}} >
                <h4 >{integration['platform']}</h4>
                {bonzo_details}
                <div className='card-container' ><h4 className='card'>{'Rules'}</h4>{rules}</div>
            </div>
        )
    }
}


class Client extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render (){
        var client = this.props.data;

        var integrations = [];
        for (var jtem of client['integrations']){
            var integration = jtem['integration'];

            integrations.push(<Integration data={integration} />);
        }

        return (
            <div>
                <div>
                    <h2>{client['name']}</h2>
                    <PageBreak />
                </div>
                <div>
                    {integrations}
                </div>
            </div>
        )
    }
}


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            details: null,
        };

        this.get_user = this.get_user.bind(this);
        this.load_user = this.load_user.bind(this);
    }

    componentDidMount(){
        this.get_user();
    }

    get_user(){
        var related = [
            'clients',
            'clients__integrations',
            'clients__integrations__rules',
            'clients__integrations__campaigns',
            'clients__integrations__users',
            'clients__integrations__users__out_of_office',
            'clients__integrations__users__working_weekends',
        ];

        ajaxWrapper('GET', '/api/user/user/?related=' + related.join(','), {}, this.load_user);
    }

    load_user(value){
        this.setState({
            details: value[0]['user'],
            loaded: true,
        });
    }

    render() {
        var clients = [];
        if (this.state.loaded){
            for (var item of this.state.details['clients']){
                var client = item['client'];

                clients.push(<Client data={client} />);
            }
        }

        return (
            <div>
				<Navbar />

                <Wrapper loaded={this.state.loaded} >
    				<Container style={{'padding-top': '20px'}} min_height={true} >
                        {clients}
    				</Container>
                </Wrapper>

				<Footer />
            </div>
        );
    }
}

export default Dashboard;

import React, { Component } from 'react';


class TextInput extends Component {
    render() {

        var layout = '';
        if (this.props.className) {
            layout = this.props.className;
        }

        var value = this.props.default;
        if (this.props.value || this.props.value == 0){
            value = this.props.value;
        }

        var style = this.props.style || {};
        var layout_style = Object.assign({position:'relative'}, this.props.layout_style);

        var input = <input type="text" className="form-control" name={this.props.name}
            onChange={this.props.handlechange} value={value} placeholder={this.props.placeholder}
            onKeyPress={this.props.handleKeyPress} style={style} autocomplete={this.props.autocomplete}
            onBlur={this.props.onBlur} />;
        if (this.props.autoFocus) {
          input = <input autoFocus type="text" className="form-control" name={this.props.name}
              onChange={this.props.handlechange} value={value} placeholder={this.props.placeholder}
              onKeyPress={this.props.handleKeyPress} style={style} autocomplete={this.props.autocomplete}
              onBlur={this.props.onBlur} />
        }

        var label = null;
        if (this.props.label && this.props.label != ''){
            label = <label>{this.props.label}</label>;
        }

        var icon = null;
        if (this.props.right_hand_icon){
            icon = <div style={{position: 'absolute', top:'0px', right: '0px'}} >
                {this.props.right_hand_icon}
            </div>;
        }

        return (
              <div className={"form-group " + layout} style={layout_style} >
                {label}
                {input}
                {icon}
              </div>
        )


    }
}

export default TextInput;

import React, { Component } from 'react';
import settings from 'base/settings.js';

import { Container, Button, Image, TextInput, List, Link, Accordion, Paragraph, RadioButton,
    TextArea, Header, LogInForm, PasswordInput, Wrapper } from 'library';



class LogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true
        };
    }

    setGlobalState() {

    }

    render() {
        var redirect = 'dashboard';

        if (localStorage.getItem('redirect') && localStorage.getItem('redirect') != 'logOut') {
            redirect = '/' + localStorage.getItem('redirect').split(',').join('/') + '/';
            //localStorage.removeItem('redirect')
        }

      var content =
        <div className="container">
            <div className="row">
                <div className="col-md-12"><br/><br/><h2 style={{textAlign:'center'}} >Log In</h2></div>
                <div className="col-md-4"></div>
                <div className="col-md-4"><LogInForm redirectUrl={redirect} defaults={['','']} submitUrl={'/users/token/'} components={[TextInput, PasswordInput]}
                    componentProps={[{'value':'','placeholder':'Email', 'name':'email','label':'Email'},{'value':'','placeholder':'Password', 'name':'password', 'label':'Password'}]} /></div>
                <div className="col-md-4"></div>
            </div>
        </div>;

        return (
          <div>
          <Wrapper loaded={true} content={content} />
          </div>
        );
    }
}

export default LogIn;
